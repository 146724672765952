body {
  font-family: "Red Hat Display", sans-serif;
  background: #1a1a24 center no-repeat;
  height: 100%;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  height: 100%;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82%;
  min-height: 800px;
  margin: auto;
}

.limit-modal .modal-content .modal-body .btn-close {
  display: none;
}

.aligh-item-start {
  align-items: flex-start !important;
}

.add-field-status {
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;

  font-size: 14px !important;
  color: #fff;
}

.modal-content {
  background: #1a1a24 center no-repeat;
  border: 0px;
  border-radius: 20px;
  color: white;
  font-style: normal;
  font-family: "Red Hat Display", sans-serif;
}

.modal-title {
  font-weight: 700;
  font-style: normal;
  font-family: "Red Hat Display", sans-serif;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin-left: 1.5rem;
}

.modal-header.btn-close {
  margin: -0.5rem 0rem -0.5rem auto !important;
}

.btn-close {
  margin: -0.5rem 0rem -0.5rem auto;
  margin-left: 94%;
}

.modal-welcome-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-close {
  opacity: 1;
}

.welcome-image {
  margin: 0px;
  text-align: center;
  margin-bottom: 2.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
}

.preloader {
  overflow: hidden;
  /*фиксированное позиционирование*/
  position: fixed;
  /* координаты положения */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* фоновый цвет элемента */
  background: #1a1a24;
  height: 1000px;
  /* размещаем блок над всеми элементами на странице (это значение должно быть больше, чем у любого другого позиционированного элемента на странице) */
  z-index: 1001;
}

.preloader__row {
  position: relative;
  top: 20%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

.preloader__item {
  position: absolute;
  display: inline-block;
  top: 0;
  background-color: #2cc4b4;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  animation: preloader-bounce 2s infinite ease-in-out;
}

.preloader__item:last-child {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloader-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.preloader-margin-top {
  margin-top: 10rem;
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
  overflow: auto;
}

a {
  text-decoration: none;
  list-style: none;
}

.icon-adm-panel-btn {
  font-size: 25px;
  margin-right: 0.6rem;
  margin-left: -1rem;
}

.icon-adm-panel-btn svg {
  color: #1a1a24 !important;
}

.text-adm-panel-btn {
  padding-bottom: 0.2rem;
}

.adm-actions-container {
  margin-top: 50%;
}

.registration-container,
.business-card-container,
.authorization-container {
  width: 100%;
}

.adm-auth-container,
.adm-add-container,
.adm-actions-container {
  margin-top: 10%;
  width: 100%;
}

.margin-bottom-2 {
  margin-bottom: 10rem !important;
}

.margin-more {
  margin-bottom: 3rem;
}

.title-left-text,
.title-left-text-md3,
.title-center-text {
  margin-bottom: 0.5rem;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  margin-top: 0px;
}

.title-center-text {
  text-align: center !important;
  color: #2cc4b4 !important;
  font-size: 46px;
}

.add-field-plus-btn {
  font-size: 18px;
  margin-bottom: 10%;
  margin-left: -2rem;
  margin-right: 0.7rem;
}

.description-center-text {
  text-align: center !important;
  font-size: 20px !important;
}

.title-left-text-md3 {
  margin-bottom: 6rem;
}

.description-left-text,
.description-center-text {
  margin-bottom: 2rem;
  color: #7a85a1;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-align: left;
  line-height: 142.2%;
}

.form-control:disabled {
  height: 3rem !important;
  background-color: #1a1a24;
  border-radius: 10px;
  border: 1.2px solid #2c2e41;
  color: white !important;
  -webkit-text-fill-color: white;
}

/* .addfield.form-floating {
  background-color: #232333 !important;
  box-shadow: inset 0 0 0 50px #232333 !important;
  border-radius: 10px;
} */

.form-floating>.addfield.form-control {
  height: 3.5rem !important;
  /* background-color: #232333 !important;
  box-shadow: inset 0 0 0 50px #232333 !important; */
  border-radius: 10px;
}

.form-floating>.form-control,
.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control:not(:placeholder-shown) {
  background-color: #1a1a24;
  -webkit-box-shadow: inset 0 0 0 50px #1a1a24;
  /* цвет вашего фона */
  padding-left: 1.3rem;
}

.card-form-input.addfield.form-control {
  min-height: 3.5rem;
  background-color: #1a1a24;
  -webkit-box-shadow: inset 0 0 0 50px #1a1a24;
  /* цвет вашего фона */
  padding-left: 1.3rem;
  border-radius: 10px;
  border: 1.2px solid #2c2e41;
  color: white;
  font-size: 1rem !important;
}

.margin .btn-text {
  border: 0px solid #2cc4b4;
  background-color: #1a1a24;
  color: #2cc4b4;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.name-of-user {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
}

.work-of-user {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: white;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-style: italic !important;
}

.left {
  text-align: left !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.white {
  color: white !important;
}

.form-control:-webkit-autofill {
  background-color: #1a1a24;
  -webkit-box-shadow: inset 0 0 0 50px #1a1a24;
  /* цвет вашего фона */
  -webkit-text-fill-color: white;
  /* цвет текста */
  padding-left: 1.3rem;
}

.btn-outlined-color.ml {
  margin-top: 6rem !important;
}

.btn-filled.ml {
  margin-top: 1rem !important;
}

.btn-fixed {
  position: sticky !important;
  z-index: 10;
  bottom: 20px;
}

.container-vcard-button {
  width: 100%;
}

.low-margin-top {
  margin-top: 1rem !important;
}

.lower-margin-top {
  margin-top: 2rem !important;
}

.hide-show-password {
  font-size: 22px;
  border: 0px;
  border-radius: 10px;
  background-color: #1a1a24;
  background-repeat: no-repeat;
  color: #7a85a1;
  width: 20%;
  height: 3rem;
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 0;
}

.input-group {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid #2c2e41;
}

.authorization-form-input input {
  border: 0px !important;
}

.authorization-ff {
  width: 80% !important;
  border: 0px !important;
}

.img-shadow-edit {
  object-fit: cover !important;
  height: 20rem !important;
  margin: auto !important;
  margin-left: -2rem !important;
  text-align: center !important;
  margin-bottom: 2.5rem !important;
  width: 24.65rem !important;
  border: 0px !important;
  border-radius: 0px 0px 80px 80px !important;
}

.img-shadow {
  height: 20rem !important;
  margin: 0px !important;
  margin-left: -16% !important;
  text-align: center !important;
  margin-bottom: 2.5rem !important;
  width: 132% !important;
  border: 0px !important;
  border-radius: 0px 0px 80px 80px !important;
  box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.5) !important;
}

.img-shadow canvas {
  object-fit: cover !important;
  position: relative;
  height: 20rem !important;
  width: 100% !important;
  border: 0px !important;
  border-radius: 0px 0px 80px 80px !important;
}

.img-shadow img {
  object-fit: cover !important;
  z-index: -1 !important;
  position: relative;
  height: 20rem !important;
  width: 100% !important;
  border: 0px !important;
  border-radius: 0px 0px 80px 80px !important;
}

.img-url-center {
  margin: 0px;
  background-size: cover;
  background-position: center;
  background-color: #1a1a24;
  background-repeat: no-repeat;
  margin-left: -16%;
  text-align: center;
  margin-bottom: 2.5rem;
  width: 132%;
  height: 20rem;
  box-shadow: inset 0 0 15px 10px rgba(0, 0, 0, 0.5);
  border: 0px;
  border-radius: 0px 0px 80px 80px;
}

.not-found-page-img {
  margin: 0px;
  background-size: 100%;
  background-color: #1a1a24;
  background-repeat: no-repeat;
  margin-left: -16%;
  text-align: center;
  margin-bottom: 2.5rem;
  width: 132%;
  height: 20rem;
}

.non-photo {
  margin: 0px;
  margin-left: -16%;
  text-align: center;
  margin-bottom: 2.5rem;
  width: 132%;
  height: 20rem;
}

.form-select {
  background-color: #1a1a24 !important;
  width: 100% !important;
  color: white;
  border-radius: 10px;
  height: 3rem;
  font-size: 14px;
  padding-left: 1.3rem;
  border: 1px solid #2c2e41;
}

.form-select>label {
  margin-left: 0.5rem;
  color: #7a85a1;
}

.form-select select {
  width: 100%;
  font-size: 14px !important;
}

.non-photo-icon {
  margin: 0px;
  background-size: 100%;
  background-color: #1a1a24;
  background-image: url("https://i.ibb.co/CsTtQHQ/add-Photo.png");
  background-repeat: no-repeat;
  margin-left: -16%;
  text-align: center;
  margin-bottom: 2.5rem;
  width: 132%;
  height: 20rem;
  border: 0px;
  border-radius: 0px 0px 80px 80px;
}

.photoform {
  color: white;
  font-size: 60px;
}

.card-container {
  margin-bottom: 2rem !important;
}

.form-floating>.form-control {
  color: white !important;
}

.form-floating>.form-control input {
  margin-left: 0.5rem;
}

.form-floating>label {
  margin-left: 0.5rem;
  color: #7a85a1;
}

.form-control:focus {
  border-color: #2cc4b4;
  box-shadow: 0 0 0 0rem #2cc4b4;
}

.input-group:focus {
  border-color: #2cc4b4;
  box-shadow: 0 0 0 0rem #2cc4b4;
}

.zoom-title {
  margin-top: -2rem;
  text-align: center !important;
  color: #2cc4b4 !important;
  font-size: 20px;
}

.zoom-style {
  margin-bottom: 2rem;
  width: 100%;
  color: #2cc4b4;
  background-color: #1a1a24 !important;
}

input[type="range"] {
  height: 28px;
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #2cc4b4;
  border-radius: 10px;
  border: 0px solid #000000;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #2c2e41;
  border-radius: 50px;
  background: #2cc4b4;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.5rem;
  height: 1.5rem;
  width: 0.8rem;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2cc4b4;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #2cc4b4;
  border-radius: 10px;
  border: 0px solid #000000;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000 !important;
  border: 1px solid #2c2e41 !important;
  height: 1.5rem;
  width: 0.8rem;
  border-radius: 10px;
  background: #2cc4b4;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #2cc4b4;
  border: 0px solid #000000;
  border-radius: 20px;
  box-shadow: 1px 1px 1px #000000;
}

input[type="range"]::-ms-fill-upper {
  background: #2cc4b4;
  border: 0px solid #000000;
  border-radius: 20px;
  box-shadow: 1px 1px 1px #000000;
}

input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #2c2e41;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #2cc4b4;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #2cc4b4;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #2cc4b4;
}

.registration-form-input,
.card-form-input,
.authorization-form-input,
.adm-auth-form-input,
.adm-add-form-input {
  font-size: 14px !important;
  color: #2c2e41;
  margin-bottom: 1rem;
}

.form-true {
  border-color: #2cc4b4 !important;
}

.form-false {
  border-color: #f31343 !important;
}

.form-floating input,
.form-floating select,
.card-form-input input,
.authorization-form-input input,
.adm-auth-form-input input,
.adm-add-form-input input {
  height: 3rem !important;
  background-color: #1a1a24;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #2c2e41;
}

.authorization-form-input {
  width: 100%;
}

.authorization-form-input input {
  border-right: 0px;
  border-radius: 10px 0px 0px 10px;
}

.adm-status-auth {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;

  font-size: 14px !important;
  color: #f31343;
}

.modal-status-auth {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;

  font-size: 18px !important;
  color: #f31343;
}

.success {
  color: #2cc4b4 !important;
}

.btn-back {
  width: 3.2rem;
  height: 3.2rem;

  font-size: 26px;

  border-radius: 10px;
  border: 1px solid #2c2e41;

  background-color: #1a1a24;
  transition: all 0.5s;
  cursor: pointer;
  color: white;
}

.btn-back:hover {
  background-color: #2cc4b4;
  border: 1px solid white;
  color: black;
}

.btn-outlined-dark {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin-top: 2.5rem;
  width: 100%;
  border: 0px;
  height: 3rem;
  text-align: center;
  color: #7a85a1;
  background-color: #2c2e41;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #2cc4b4;
  font-size: 1rem;
}

.btn-outlined-color {
  border: 1.2px solid #2cc4b4;
  background-color: #1a1a24;
  color: #2cc4b4;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin-top: 2.5rem;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.5s;
}

.modal-header {
  border-bottom: 0;
}

/* .empty-card-text {
  margin-top: 18rem;
} */

.icon-btn-spy {
  font-size: 20px;
  margin-right: 0.5rem;
  color: #2cc4b4;
}

textarea.form-control {
  color: white;
  background-color: #1a1a24;
  -webkit-box-shadow: inset 0 0 0 50px #1a1a24;
  /* цвет вашего фона */
  border: 1.2px solid #2c2e41;
  border-radius: 10px;
  min-height: 3rem;
  overflow: hidden;
  resize: none;
}

.form-control {
  color: white !important;
  background-color: #1a1a24 !important;
  -webkit-box-shadow: inset 0 0 0 50px #1a1a24 !important;
  /* цвет вашего фона */
}

.btn-outlined-color.spy {
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-color: #2c2e41;
  color: white;
  font-weight: normal;
  font-size: 1rem;
  align-items: flex-start;
  overflow: hidden;
}

.title-left-text.adm {
  margin-bottom: 4rem;
}

.btn-outlined-color:hover {
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  border-color: #ffaa6b;
}

.btn-filled.red {
  background-image: linear-gradient(180deg,
      #f31343 0%,
      #1d3f3a 50%,
      #2cc4b4 100%);

  background-position: 98% 0;
}

.btn-filled.red:hover {
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  box-shadow: 0 0 0 0 rgba(0, 40, 120, 0);
  background-position: 0 0;
}

.btn-filled {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin-top: 2.5rem;
  width: 100%;
  border: 0px !important;
  height: 3rem;
  text-align: center;
  color: #1a1a24;
  background-color: #2cc4b4;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #2cc4b4;
  font-size: 1rem;
  transition: all 0.5s;
  background-image: linear-gradient(45deg,
      #ffaa6b 0%,
      #1d3f3a 50%,
      #2cc4b4 100%);
  background-position: 100% 0;
  background-size: 200% 200%;
}

.btn-filled:hover {
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  box-shadow: 0 0 0 0 rgba(0, 40, 120, 0);
  background-position: 0 0;
}

.forget_pass {
  text-align: center;
  margin-top: 0.5rem;
}

.forget_pass a {
  color: #7a85a1;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-size: 12px;
  text-align: left;
  line-height: 142.2%;
}

.loadIcon svg {
  animation: 10s linear 0s normal none infinite running loadIcon;
  -webkit-animation: 10s linear 0s normal none infinite running loadIcon;
}

@keyframes loadIcon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadIcon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadIcon svg {
  margin-bottom: 3px;
}

@media (prefers-reduced-motion: no-preference) {
  .loadIcon {
    animation: App-logo-spin 5s linear 0s infinite;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .main-container {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .main-container {
    width: 30%;
  }
}