@font-face {
  font-family: "Montserrat-Bold";
  src: local("MontserratRegular"),
    url("../resources/fonts/Montserrat/static/Montserrat-Bold-2.ttf")
      format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("MontserratRegular"),
    url("../resources/fonts/Montserrat/static/Montserrat-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("MontserratMedium"),
    url("../resources/fonts/Montserrat/static/Montserrat-Medium.ttf")
      format("truetype");
}

:root {
  --green: #2cc4b4;
  --white: #f4f4f4;
  --background: #1a1a24;
  --red: #f31343;
  --light-background: linear-gradient(90deg, #0f5f51 0%, var(--green) 100%);
  --form-width: 17vw;
  --global-margin-desktop: 80vw;
  --global-margin-mobile: 60vw;
}

body {
  background: var(--background) center no-repeat;
  height: 100%;
}

.landing-header {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-mainscreen {
  margin-top: -0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row-inside {
  margin-bottom: 1rem;
  height: 7rem;
}

.landing-about {
  width: var(--global-margin-desktop);
  margin: auto;
}

.about-row-outer {
  margin-top: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-column-outer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.about-row-inside {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 9rem;
}

.about-column-inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about-col-icon {
  flex-basis: 30%;
}

.about-col-title {
  flex-basis: 70%;
}

.about-col-text {
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
}

.landing-mainscreen p,
.landing-mainscreen h1 {
  padding: 0;
}

.header-row {
  display: flex;
  flex-wrap: wrap;
}

.header-column {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  align-self: center;
}

.mainscreen-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mainscreen-row::before {
  content: none;
}

.mainscreen-column {
  flex-basis: 48%;
  display: flex;
  align-items: center;
}

.mainscreen-column-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.mainscreen-text {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
}

.mainscreen-text::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: var(--light-background);
  mix-blend-mode: screen;
  opacity: 0.9;
  filter: blur(10rem);
  z-index: -1;
}

.landing-text-mw {
  width: 60% !important;
}

#text-end {
  text-align: end !important;
  align-self: end !important;
}

#text-start {
  text-align: start !important;
  align-self: start !important;
}

#text-center {
  text-align: center !important;
  align-self: center !important;
}

#logo {
  width: 200px;
}

.mockup {
  width: 26vw;
}

#about-icon {
  width: 100px
}

#cube {
  width: 25vw;
}

.text-red {
  color: var(--red) !important;
}

.text-green {
  color: var(--green) !important;
}

.contact-info-message {
  padding-top: 1rem;
  text-align: start;
  justify-content: center;
  align-items: center;
}

#mockup-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mockup-image {
  position: relative;
}

.mockup-image::before {
  content: "";
  position: absolute;
  top: 14rem;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: var(--light-background);
  opacity: 1;
  filter: blur(200px);
  z-index: -1;
}

#shadow {
  margin-top: -0.5rem;
  width: 24rem;
  mix-blend-mode: multiply;
  display: flex;
  align-items: center;
}

.shadow-div {
  background-color: var(--background);
  display: flex;
  z-index: -10;
}

.padding-bottom-7 {
  padding-bottom: 7rem;
}

.landing-text-padding {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  margin: 0px !important;
}

.item-col-end {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.item-row-end {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.landing-container {
  width: 94vw;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin: auto;
}

.text-little {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 130%;
  letter-spacing: 0.05em;
  opacity: 0.7;
  color: var(--white);
}

.list-item {
  padding-bottom: 0.5rem;
}

.text-middle {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--white);
}

.text-big {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--white);
}

.text-big-main {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  color: var(--white);
}

.mainscreen-stroke {
  color: transparent;
  -webkit-text-stroke: 0.11rem var(--white);
}

.language-button {
  border: medium none;
  background-image: none;
  background: transparent;
  background-color: transparent;

  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: var(--white);
  opacity: 0.7;
}

.landing-product {
  width: var(--global-margin-desktop);
  margin: auto;
  margin-top: 14rem;
}

.product-row-outer {
  display: grid;
  grid-template: 100% / 10% 60% 30%;
  grid-template-areas: ". product-items product-text";
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.product-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.product-column {
  display: flex;
  flex-direction: column;
}

.product-column-text {
  grid-area: product-text;
  position: relative;
}

.product-column-text::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: var(--light-background);
  mix-blend-mode: screen;
  opacity: 0.9;
  filter: blur(5rem);
  z-index: -1;
}

#product-item-card {
  width: 20rem;
}

#product-item-tags {
  width: 18rem;
}

.product-img-link {
  width: 100%;
  height: 100%;
}

.product-column-items {
  grid-area: product-items;
  position: relative;
  display: grid;
  grid-template: repeat(1, 100%) / repeat(2, 50%);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.product-column-items::before {
  content: none;
}

.product-text {
  display: inline;
  text-align: right;
  grid-row-start: 1;
  grid-column-start: 1;
}

.outer-light {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

#product-item-img {
  width: 140px;
  height: 140px;
}

.product-stroke {
  color: transparent;
  -webkit-text-stroke: 0.11rem var(--white);
  display: inline;
  text-align: end;
}

.landing-cube {
  position: relative;
  margin-left: -3vw;
  width: 100vw;
  padding-bottom: 14rem;
}

.landing-cube::before {
  content: "";
  background-image: url(../resources/img/cube-grid.svg);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.35;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.cube-row {
  margin-top: 14rem;
  display: grid;
  grid-template: 100% / 55% 35% 10%;
  grid-template-areas: "cube-text cube-img .";
  width: var(--global-margin-desktop);
  margin-left: auto;
  margin-right: auto;
}

.cube-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cube-column-text {
  grid-area: cube-text;
  justify-content: flex-end;
  align-self: center;
}

.cube-img {
  grid-area: cube-img;
  align-items: flex-end;
}

.landing-buy {
  width: var(--global-margin-mobile);
  margin: auto;
}

.buy-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.buy-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buy-text {
  position: relative;
  flex-basis: 50%;
}

.buy-text::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: var(--light-background);
  mix-blend-mode: screen;
  opacity: 0.9;
  filter: blur(8rem);
  z-index: -1;
}

.buy-button {
  width: var(--form-width);
}

#buy-text-1 {
  text-align: start;
  align-self: flex-start;
}

#buy-text-2 {
  text-align: start;
  align-self: flex-start;
}

#buy-text-3 {
  text-align: end;
  align-self: flex-end;
}

.landing-button {
  font-family: "Montserrat-Medium", sans-serif;
  text-transform: uppercase;
  width: 100%;
  border: 0px !important;
  height: 3rem;
  text-align: center;
  background-color: var(--green);
  cursor: pointer !important;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 var(--green);
  background-image: linear-gradient(
    45deg,
    #ffaa6b 0%,
    #1d3f3a 50%,
    var(--green) 100%
  );
  background-position: 100% 0;
  background-size: 200% 200%;
  z-index: 5;
}

.landing-button:hover {
  box-shadow: 0 0 0 0 rgba(0, 40, 120, 0);
  background-position: 0 0;
}

.list-item::marker {
  color: var(--green)
}

.landing-button-disabled {
  font-family: "Montserrat-Medium", sans-serif;
  text-transform: uppercase;
  width: 100%;
  height: 3rem !important;
  background-color: var(--background);
  border-radius: 10px;
  border: 1.2px solid var(--green);
  color: var(--white) !important;
  -webkit-text-fill-color: var(--white);
  z-index: 10;
}

.buy-stroke {
  color: transparent;
  -webkit-text-stroke: 0.11rem var(--white);
}

.stroke-green {
  background: linear-gradient(-86deg, #0f5f51 0%, var(--green) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--background);
  -webkit-text-stroke: 0.11rem transparent;
}

.landing-contact {
  position: relative;
  margin-left: -3vw;
  width: 100vw;
}

.landing-contact::before {
  content: "";
  background-image: url(../resources/img/Vector.svg);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.contact-column-outer {
  margin-top: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}

.contact-row-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: var(--form-width);
}

.contact-title {
  margin-bottom: 3rem;
}

.contact-article {
  display: flex;
  justify-content: center;
  position: relative;
  flex-basis: 30%;
  margin: auto;
  margin-top: 4rem;
}

.contact-form form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: var(--form-width);
}

.contact-input.form-floating {
  margin-bottom: 2rem;
}

.contact-input.form-floating > .form-control,
.contact-input.form-floating > .form-control:-webkit-autofill,
.contact-input.form-floating > .form-control:not(:placeholder-shown) {
  background-color: var(--background);
  padding-left: 1.3rem;
  -webkit-box-shadow: inset 0 0 0 50px var(--background);
  width: var(--form-width);
}

.contact-input.form-control:-webkit-autofill {
  background-color: var(--background);
  -webkit-box-shadow: inset 0 0 0 50px var(--background);
  -webkit-text-fill-color: var(--white);
  padding-left: 1.3rem;
}

.form-green {
  border-color: var(--green) !important;
}

.form-red {
  border-color: var(--red) !important;
}

.landing-footer {
  width: 100%;
  margin: auto;
  margin-top: 4rem;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-input.form-floating > label {
  font-family: "Montserrat-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #f2f2f2;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: var(--global-margin-desktop);
  margin: auto;
}

.info-text {
  position: relative;
  justify-content: flex-start;
  margin: 0;
}

.info-img::before,
.info-text::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: var(--light-background);
  mix-blend-mode: screen;
  opacity: 0.9;
  filter: blur(10rem);
  z-index: -1;
}

.info-img {
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
}

#card-img {
  width: 26rem;
}

.info-column {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
}

@media (max-width: 1525px) {
  .text-big-main {
    font-size: 4rem;
  }

  .text-big {
    font-size: 2.2rem;
  }

  #shadow {
    margin-top: 0.5rem;
    width: 15rem;
  }
}

@media (max-width: 1490px) {
  .about-row-inside {
    min-height: 12rem;
  }

  #card-img {
    width: 20rem;
  }
}

@media (max-width: 1400px) {
  .text-middle {
    font-size: 1.1rem;
  }

  #product-item-card {
    width: 15rem;
  }
  
  #product-item-tags {
    width: 13rem;
  }
}



@media (max-width: 1210px) {
  .text-big-main {
    font-size: 3.5rem;
  }

  #shadow {
    width: 12rem;
  }

  .buy-button {
    display: flex;
  }
}

@media (max-width: 1060px) {
  .text-big-main {
    font-size: 3rem;
  }

  .text-big {
    font-size: 1.6rem;
  }

  .text-little {
    font-size: 0.9rem;
  }

  .text-middle {
    font-size: 1rem;
  }

  .padding-bottom-7 {
    padding-bottom: 2rem;
  }

  #product-item-card {
    width: 10rem;
  }
  
  #product-item-tags {
    width: 10rem;
  }
}

@media (max-width: 990px) {
  :root {
    --form-width: 25vw;
  }

  .text-middle {
    font-size: 0.9rem;
  }

  .product-row-outer {
    grid-template: 100% / 5% 60% 35%;
    grid-template-areas: ". product-items product-text";
  }
  
  .cube-row {
    grid-template: 100% / 55% 40% 5%;
    grid-template-areas: "cube-text cube-img .";
  }
}

@media (max-width: 910px) {
  .text-big-main {
    font-size: 2.5rem;
  }

  .text-middle {
    font-size: 0.8rem;
  }

  .padding-bottom-7 {
    padding-bottom: 1rem;
  }

  #shadow {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  .landing-mainscreen {
    width: var(--global-margin-desktop);
    margin: auto;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
  }

  #shadow {
    margin-top: 1rem;
    width: 20rem;
  }

  :root {
    --form-width: 40vw;
  }

  .mainscreen-row::before {
    content: "";
    background: var(--light-background);
    background-repeat: no-repeat;
    background-size: 50%;
    opacity: 1;
    filter: blur(200px);

    position: absolute;
    top: 1rem;
    right: 0px;
    bottom: 0px;
    left: 30vw;
    z-index: -1;
  }

  .text-little {
    font-size: 1rem;
  }

  .text-big-main {
    font-size: 3rem;
  }

  .text-big {
    font-size: 1.6rem;
  }

  .text-middle {
    font-size: 1.2rem;
  }

  .landing-about,
  .landing-product {
    width: var(--global-margin-mobile);
  }

  .product-column-text {
    margin-bottom: 4rem;
  }

  .product-column-text::before {
    content: none;
  }

  .product-column-items::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: var(--light-background);
    mix-blend-mode: screen;
    opacity: 0.9;
    filter: blur(7rem);
    z-index: -1;
  }

  .buy-stroke {
    -webkit-text-stroke: 0.05rem var(--white);
  }

  .buy-row {
    flex-direction: column;
  }

  .buy-button {
    align-self: center;
    min-width: 50vw;
    margin-top: 2rem;
  }

  #buy-text-1 {
    text-align: start;
    align-self: flex-start;
    margin-bottom: 0.1rem;
  }

  #buy-text-2 {
    text-align: end;
    align-self: flex-end;
    margin-bottom: 0.1rem;
  }

  #buy-text-3 {
    text-align: center;
    align-self: center;
    margin-bottom: 0.1rem;
  }

  .about-row-outer,
  .about-row-inside {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-row-inside {
    min-height: 7.5rem;
    margin-top: 1rem;
    justify-content: center;
  }

  .about-column-outer {
    width: 100%;
    margin-top: 2rem;
    align-items: flex-start;
  }

  .product-row-outer {
    display: flex;
    flex-direction:column-reverse;
    align-items: flex-start;
  }

  .product-column-items{
    display: flex;
    flex-direction:column-reverse;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  #product-item-card {
    width: 16rem;
  }
  
  #product-item-tags {
    width: 16rem;
  }
  
  .cube-row {
    grid-template: 100% / 55% 40% 5%;
    grid-template: 50% 50% / 100%;
    grid-template-areas: 
    "cube-text"
    "cube-img";
  }

  .cube-row {
    flex-direction: column;
    width: var(--global-margin-mobile);
  }

  .landing-cube::before {
    content: none;
  }

  .cube-column-text {
    max-width: var(--global-margin-mobile);
    margin-bottom: -1rem;
    z-index: 10;
  }

  .cube-img {
    align-items: flex-end;
    flex-basis: 100%;
  }

  #cube {
    width: 30vw;
  }

  .cube-img::before {
    content: "";
    background-image: url(../resources/img/cube-grid.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.6;
    position: absolute;
    top: 30%;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .contact-article {
    width: var(--global-margin-mobile);
  }

  .landing-text-mw {
    width: auto !important;
  }

  #logo {
    padding-left: 5px;
    width: 150px;
  }

  .mainscreen-column {
    flex-basis: 100%;
  }

  .mockup {
    margin-top: 3rem;
    width: var(--global-margin-mobile);
  }

  .mockup-image::before {
    content: none;
  }

  .mainscreen-text::before {
    content: none;
  }

  .mainscreen-row {
    flex-direction: column;
  }

  .info-row {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .info-text {
    margin-top: 4rem;
  }

  .contact-article {
    margin-top: 4rem;
  }
}

@media (max-width: 520px) {
  .landing-about, .landing-product {
      width: var(--global-margin-desktop);
  }
  .about-row-outer {
    margin-top: 0rem;
  }

  #shadow {
    width: 12rem;
  }

  .text-big-main {
    font-size: 2.5rem;
  }

  .text-big {
    font-size: 1.5rem;
  }

  :root {
    --form-width: 50vw;
  }

  .product-column-items {
    grid-template: repeat(3, 30%) / repeat(2, 50%);
  }

  .product-stroke {
    -webkit-text-stroke: 0.03rem var(--white);
  }

  .about-row-outer {
    max-width: 100%;
    width:  auto !important;
  }
}

@media (max-width: 600px) {
  #cube {
    width: 40vw;
  }
}

@media (max-width: 450px) {
  .text-big-main {
    font-size: 2.2rem;
  }
  .text-big {
    font-size: 1.2rem;
  }

  .landing-mainscreen {
    width: var(--global-margin-desktop);
  }

  .contact-article {
    width: var(--global-margin-desktop);
  }

  #shadow {
    width: 10rem;
  }
}

@media (max-width: 415px) {

  .text-big {
    font-size: 1.3rem;
  }

  .text-middle {
    font-size: 1rem;
  }

  .contact-form form,
  .contact-row-inner,
  .contact-input {
    width: 65vw;
  }

  .contact-input.form-floating > .form-control,
  .contact-input.form-floating > .form-control:-webkit-autofill,
  .contact-input.form-floating > .form-control:not(:placeholder-shown) {
    width: 65vw;
  }

  .buy-button {
    min-width: 65vw;
  }

  .cube-row {
    margin-top: 7rem;
  }

  .landing-cube {
    padding-bottom: 7rem;
  }

  .contact-column-outer {
    margin-top: 7rem;
  }
}

@media (max-width: 380px) {
  .text-big-main {
    font-size: 1.6rem;
  }

  #shadow {
    width: 8rem;
  }
}
